import { Controller } from "@hotwired/stimulus";
import { createApp } from "vue";
import RecurrenceForm from "../components/RecurrenceForm.vue";

export default class extends Controller {
  static targets = ["recurrenceField", "formContainer"];

  connect() {
    // have to use querySelector instead of target because it's outside the controller
    this.startDatetimeFieldTarget = document.querySelector('[data-recurrence-target="startDatetimeField"]');
    this.startDatetimeTzTarget = document.querySelector('[data-recurrence-target="startDatetimeTz"]');

    this.app = createApp(RecurrenceForm, {
      initialRrule: this.recurrenceFieldTarget.value,
      startDateTime: this.startDatetimeFieldTarget ? this.startDatetimeFieldTarget.value : '',
      timezone: this.startDatetimeTzTarget ? this.startDatetimeTzTarget.value : '',
      updateRecurrenceField: this.updateRecurrenceField.bind(this),
    });

    this.app.mount(this.formContainerTarget);

    this.startDatetimeFieldTarget.addEventListener('change', this.handleStartDateTimeChange.bind(this));
  }

  disconnect() {
    if (this.app) {
      this.app.unmount();
    }
    this.startDatetimeFieldTarget.removeEventListener('change', this.handleStartDateTimeChange.bind(this));
  }

  handleStartDateTimeChange(event) {
    const newStartDateTime = event.target.value;
    this.app._instance.props.startDateTime = newStartDateTime;
  }

  updateRecurrenceField(rrule) {
    if (this.recurrenceFieldTarget.value === rrule) return;

    this.recurrenceFieldTarget.value = rrule;
    this.recurrenceFieldTarget.dispatchEvent(new Event('change'));
  }
}
