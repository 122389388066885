import {Controller} from "@hotwired/stimulus"
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["form", "progress", "saveButton"];

  connect() {
    this.save = debounce(this._save.bind(this), 200); // Adjust debounce time as needed
    this.isSaving = false;
    this.disableSaveButton();
    this.addInputListeners();
    this.saveButtonTarget.addEventListener('click', (e) => {
      e.preventDefault();
      this.save();
    });
    this.formTarget.addEventListener('turbo:submit-end', (e) => {
      if (!e.detail.success) { return; }
      this.isSaving = false;
      this.hideProgress();
      this.disableSaveButton();
    });
  }

  addInputListeners() {
    const elements = this.formTarget.querySelectorAll('input[name^="calendulate_request"], select[name^="calendulate_request"], textarea[name^="calendulate_request"]');
    elements.forEach(element => {
      element.addEventListener('input', () => {
        this.enableSaveButton();
      });
      element.addEventListener('change', () => {
        this.save()
      });
    });
  }

  showProgress() { this.progressTarget.classList.remove('hidden'); }
  hideProgress() { this.progressTarget.classList.add('hidden'); }

  enableSaveButton() {
    this.saveButtonTarget.disabled = false;
    this.saveButtonTarget.value = "Save";
  }
  disableSaveButton() {
    this.saveButtonTarget.disabled = true;
    this.saveButtonTarget.value = "Saved";
  }

  _save() {
    if (this.isSaving) { return; }
    this.isSaving = true;
    this.showProgress();
    this.formTarget.requestSubmit();
  }

}
