<template>
  <div>
    <div class="flex items-center mt-2 mb-3">
      <label :for="'repeat' + uid" class="text-sm font-medium text-gray-700">
        <input
            type="checkbox"
            :id="'repeat' + uid"
            v-model="repeat"
            @click="formManuallyToggled = true"
            class="mr-2 text-orange-600 focus:ring focus:ring-orange-300 focus:ring-opacity-50"
        />
        <span>Repeat</span>
      </label>
    </div>

    <div v-show="repeat" class="mb-4 p-2 pl-6 rounded-md bg-amber-100">
      <label v-if="repeat && rrule" class="flex cursor-pointer" :for="'collapse-repeat-'+uid" title="Toggle form">
        <button @click="toggleShowForm" :id="'collapse-repeat-'+uid" class="block mr-3 appearance-none text-xs text-gray-700 transition-transform" :class="{ '-rotate-45 -translate-y-1': showForm, '-rotate-135 -translate-y-0 -translate-x-1': !showForm }">
          ︎◣
        </button>
        <span class="block text-sm border-b border-dashed border-gray-900">
          Repeats {{ rrule?.toText() }}
        </span>
      </label>

      <div v-show="showForm" >
        <div class="flex items-center mb-3 mt-4">
          <label class="text-sm w-10">Every</label>
          <input
              class="w-16 mx-2 calendulate-form-input"
              type="number"
              v-model="repeatEvery"
              min="1"
          />
          <select class="calendulate-form-input" v-model="interval">
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
        </div>

        <!-- Weekly Recurrence -->
        <div v-if="interval === 'week'" class="mb-4">
          <div class="flex items-center mt-3">
            <label class="mr-2 w-10 text-sm flex-shrink-0">On</label>
            <div class="flex flex-wrap">
              <label
                  v-for="day in weekDaysList"
                  :key="day"
                  class="flex items-center"
                  :title="day"
              >
                <input
                    class="checkbox-box"
                    type="checkbox"
                    :value="day"
                    v-model="weekDays"
                />
                <span>{{ day.slice(0, 2) }}</span>
              </label>
            </div>
          </div>
        </div>

        <!-- Monthly & Yearly Recurrence -->
        <div v-if="interval === 'month' || interval === 'year'" class="my-2">
          <!-- Month Selector (for Yearly Recurrence) -->
          <div v-if="interval === 'year'" class="ml-12 grid grid-cols-6 gap-2 inline-grid">
            <label
                v-for="(month, index) in monthsList"
                :key="month"
                class="flex items-center"
                :title="month"
            >
              <input
                  class="checkbox-box"
                  type="checkbox"
                  :value="index"
                  v-model="yearlyMonths"
              />
              <span class="w-10">{{ month.slice(0, 3) }}</span>
            </label>
          </div>

          <div class="flex items-start my-2 text-sm">
            <label class="w-10 flex-shrink-0">On</label>
            <div class="flex flex-wrap xs:flex-nowrap">
              <label class="mx-2 flex items-start sm:items-center" title="Dates of the Month (e.g. 1st, 15th)">
                <input
                    class="mt-1 sm:mt-0 mr-2 radio"
                    type="radio"
                    value="date"
                    v-model="monthlyOn"
                    :name="'monthlyOn' + uid"
                    checked
                />
                <span>Month Dates (e.g.&nbsp;1st,&nbsp;15th)</span>
              </label>
              <label class="mx-2 flex items-start sm:items-center" title="Days of the Week (e.g. First Monday, Last Friday)">
                <input
                    class="mt-1 sm:mt-0 mr-2 radio"
                    type="radio"
                    value="day"
                    v-model="monthlyOn"
                    :name="'monthlyOn' + uid"
                />
                <span>Days&nbsp;of&nbsp;the&nbsp;week (e.g.&nbsp;First&nbsp;Monday)</span>
              </label>
            </div>
          </div>

          <!-- Monthly Dates calendar -->
          <div v-if="monthlyOn === 'date'" class="mb-1 ml-12 grid grid-cols-7 inline-grid">
            <label
                v-for="day in daysInMonth"
                :key="day"
                class="flex items-center"
            >
              <input
                  class="checkbox-box"
                  type="checkbox"
                  :value="day"
                  v-model="monthDates"
              />
              <span class="w-10">{{ day }}</span>
            </label>
            <label class="col-span-4 flex items-center">
              <input
                  class="checkbox-box"
                  type="checkbox"
                  value="-1"
                  v-model="monthDates"
              />
              <span class="w-15">Last</span>
            </label>
          </div>

          <!-- Monthly Days picker -->
          <div v-if="monthlyOn === 'day'" class="flex">
            <div class="w-12">
              <button @click="addMonthlyDay" class="appearance-none mt-2 ml-2 w-7 h-7 rounded-full border border-transparent text-amber-500 hover:border-amber-500 active:text-white active:bg-amber-500" title="Add a day">
                ＋
              </button>
            </div>
            <div>
              <div v-for="(day, index) in monthlyDays" :key="index" class="flex items-center">
                <select v-model="monthlyDays[index]" class="calendulate-form-input">
                  <option value="1">First</option>
                  <option value="2">Second</option>
                  <option value="3">Third</option>
                  <option value="4">Fourth</option>
                  <option value="-1">Last</option>
                </select>
                <select v-model="monthlyWeekdays[index]" class="ml-2 calendulate-form-input">
                  <option value="SU">Sun</option>
                  <option value="MO">Mon</option>
                  <option value="TU">Tue</option>
                  <option value="WE">Wed</option>
                  <option value="TH">Thu</option>
                  <option value="FR">Fri</option>
                  <option value="SA">Sat</option>
                </select>
                <button v-if="monthlyDays.length > 1" @click="removeMonthlyDay(index)" class="appearance-none ml-1 w-7 h-7 rounded-full border border-transparent text-orange-500 hover:border-orange-500 active:text-white active:bg-orange-500" title="Remove the day">
                  ✕
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- End Date: Never / On [select date] / After [input occurrences] times -->
        <div class="flex items-center my-2 text-sm">
          <label class="w-10 flex-shrink-0">Ends</label>
          <select class="calendulate-form-input mx-2" v-model="end">
            <option value="never">Never</option>
            <option value="date">On</option>
            <option value="after">After</option>
          </select>

          <div v-if="end === 'date'" class="flex items-center">
            <input class="calendulate-form-input" type="date" v-model="endDate" :min="startDate.toISOString().split('T')[0]" />
          </div>
          <div v-if="end === 'after'" class="flex items-center">
            <input
                class="w-20 calendulate-form-input"
                type="number"
                v-model="endOccurrences"
                min="1"
            />
            <span class="ml-2">times</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RRule, rrulestr } from "rrule";

export default {
  name: "RecurrenceForm",
  props: {
    initialRrule: {
      type: String,
      default: ""
    },
    startDateTime: {
      type: String,
      required: true
    },
    timezone: {
      type: String,
      required: true
    },
    updateRecurrenceField: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      uid: Math.random().toString(36).substr(2, 9),
      repeat: false,
      showForm: false,
      formManuallyToggled: false,
      interval: "day",
      repeatEvery: 1,
      weekDays: [],
      yearlyMonths: [],
      monthlyOn: "date",
      monthDates: [],
      monthlyDays: ["1"],
      monthlyWeekdays: ["SU"],
      end: "never",
      endDate: "",
      endOccurrences: 1,
    };
  },
  computed: {
    weekDaysList() {
      return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    },
    monthsList() {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
    },
    daysInMonth() {
      return Array.from({ length: 31 }, (_, i) => i + 1);
    },
    rrule() {
      return this.generateRrule();
    },
    startDate() {
      return new Date(this.startDateTime);
    }
  },
  methods: {
    addMonthlyDay() {
      this.monthlyDays.push("1"); // Default value for new entries
      this.monthlyWeekdays.push("SU"); // Default value for new entries
    },
    removeMonthlyDay(index) {
      if (this.monthlyDays.length > 1) {
        this.monthlyDays.splice(index, 1);
        this.monthlyWeekdays.splice(index, 1);
      }
    },
    generateRrule() {
      if (!this.repeat) {
        return;
      }

      const options = {
        freq: this.interval === 'day' ? RRule.DAILY :
            this.interval === 'week' ? RRule.WEEKLY :
                this.interval === 'month' ? RRule.MONTHLY : RRule.YEARLY,
        interval: this.repeatEvery,
        dtstart: new Date(this.startDateTime),
        tzid: this.timezone,
      };

      if (this.interval === 'week' && this.weekDays.length > 0) {
        options.byweekday = this.weekDays.map(day => RRule[day.toUpperCase().slice(0, 2)]);
      }

      if ((this.interval === 'month' || this.interval === 'year') && this.monthlyOn === 'date') {
        options.bymonthday = this.monthDates;
      } else {
        options.bymonthday = [];
      }

      if ((this.interval === 'month' || this.interval === 'year') && this.monthlyOn === 'day') {
        options.byweekday = this.monthlyDays.map((day, index) => RRule[this.monthlyWeekdays[index]].nth(parseInt(day)));
        this.monthlyOn = 'day';
      }

      if (this.interval === 'year') {
        options.bymonth = this.yearlyMonths.map(month => month + 1);
      }

      if (this.end === 'date' && this.endDate) {
        const endDate = new Date(this.endDate);
        endDate.setHours(23, 59, 59, 999);
        options.until = endDate;
      }

      if (this.end === 'after') {
        options.count = this.endOccurrences;
      }

      return new RRule(options);
    },
    parseRrule() {
      if (!this.initialRrule) { return this.repeat = false;}

      let rruleObj;
      try {
        rruleObj = rrulestr(this.initialRrule);
      } catch (e) {
        console.error(e);
        return;
      }
      const options = rruleObj.options;

      this.repeat = true;
      this.interval = this.getIntervalString(options.freq);
      this.repeatEvery = options.interval;

      const weekdayMapping = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

      if (options.bynweekday) {
        this.monthlyDays = options.bynweekday.map(nw => nw[1].toString());
        this.monthlyWeekdays = options.bynweekday.map(nw => {
          const day = nw[0];
          const rruleDay = weekdayMapping[day];
          return rruleDay ? rruleDay.toString() : '';
        });
        this.monthlyOn = 'day';
      } else if (options.byweekday && options.bysetpos) {
        this.monthlyDays = options.bysetpos.map(pos => pos ? pos.toString() : '');
        this.monthlyWeekdays = options.byweekday.map(day => day ? day.toString() : '');
        this.monthlyOn = 'day';
      } else if (options.byweekday) {
        const weekDaysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        this.weekDays = options.byweekday.map(day => weekDaysList[day]);
        this.monthlyOn = 'day';
      }

      if (options.bymonthday && options.bymonthday.length > 0) {
        this.monthDates = options.bymonthday;
        this.monthlyOn = 'date';
      }

      if (options.bymonth) {
        this.yearlyMonths = options.bymonth.map(month => month - 1);
      }

      if (options.until) {
        this.end = 'date';
        this.endDate = options.until.toISOString().split('T')[0];
      } else if (options.count) {
        this.end = 'after';
        this.endOccurrences = options.count;
      } else {
        this.end = 'never';
      }
    },
    getIntervalString(freq) {
      switch (freq) {
        case RRule.DAILY:
          return 'day';
        case RRule.WEEKLY:
          return 'week';
        case RRule.MONTHLY:
          return 'month';
        case RRule.YEARLY:
          return 'year';
        default:
          return 'day';
      }
    },
    toggleShowForm() {
      this.showForm = !this.showForm;
    }
  },
  mounted() {
    if (this.initialRrule && this.initialRrule.length > 0) {
      this.parseRrule();
    }
  },
  watch: {
    rrule: {
      handler(rrule) {
        this.updateRecurrenceField(rrule?.toString() || "");
      },
      immediate: true
    },
    repeat: {
      handler(repeat) {
        if (repeat && this.formManuallyToggled) {
          this.showForm = true;
        }
      }
    }
  }
}
</script>
