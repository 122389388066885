import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timezone"]

  connect() {
    this.setTimezone()
  }

  setTimezone() {
    this.timezoneTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone
  }
}
