import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

export default class extends Controller {
  static targets = ['input', 'longitude', 'latitude', 'geoIndicator'];

  initialize() {
    this.placeChanged = this.placeChanged.bind(this);
  }

  connect() {
    if (typeof this.autocomplete === 'undefined') {
      this.initAutocomplete();
    }
    this.updateGeoIndicator();
  }

  initAutocomplete() {
    const loader = new Loader({
      apiKey: "AIzaSyDWxhJYwoGlj8mZSkN_CmkFB6ncXdmyTYc",
      version: "quarterly",
      libraries: ["places"]
    })

    loader
      .importLibrary('places')
      .then(({Autocomplete}) => {
        this.autocomplete = new Autocomplete(this.inputTarget, this.autocompleteOptions);
        this.autocomplete.addListener('place_changed', this.placeChanged);
      })
      .catch((_e) => {
        console.error("error loading gmaps")
      })
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace();

    if (this.place.geometry !== undefined) {
      this.setGeometry(this.place.geometry.location.lng().toString(), this.place.geometry.location.lat().toString());
      this.updateGeoIndicator();
    } else {
      this.resetGeo();
    }
  }

  resetGeo() {
    this.setGeometry('', '');
  }

  setGeometry(lat, long) {
    if (this.hasLongitudeTarget) this.longitudeTarget.value = lat;
    if (this.hasLatitudeTarget) this.latitudeTarget.value = long;
  }

  get autocompleteOptions() {
    return {
      fields: ['geometry']
    };
  }

  preventSubmit(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }

  updateGeoIndicator() {
    if (this.latitudeTarget.value && this.longitudeTarget.value) {
      this.geoIndicatorTarget.classList.remove('hidden');
    } else {
      this.geoIndicatorTarget.classList.add('hidden');
    }
  }
}
