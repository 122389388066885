import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recaptchaToken", "submitButton"]

  connect() {
    this.element.addEventListener('submit', this.submit.bind(this))
  }

  submit(event) {
    if (!window.recaptcha_site_key) {
      console.log('No reCAPTCHA site key found. Submitting form without reCAPTCHA.')
      this.element.submit();
      return
    }

    event.preventDefault();
    this.submitButtonTarget.disabled = true
    grecaptcha.ready(() => {
      grecaptcha.execute(window.recaptcha_site_key, { action: 'submit' }).then(token => {
        this.recaptchaTokenTarget.value = token
        this.element.submit()
      })
    })
  }
}
