import {Controller} from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = { "requestId": Number }

  connect() {
    this.calendulateChannel = consumer.subscriptions.create(
      { channel: "CalendulateChannel", id: this.requestIdValue },
      {
        received: (data) => {
          this.processData(data)
        }
      }
    );
  }

  disconnect() {
    if (this.calendulateChannel) {
      this.calendulateChannel.unsubscribe();
    }
  }

  processData(data) {
    if (data.completed) {
      const frame = document.getElementById(`calendulate_request_${this.requestIdValue}`);
      if (frame) {
        document.getElementById(`calendulate_request_${this.requestIdValue}`).src = window.location.pathname;
      } else {
        Turbo.visit(window.location.pathname);
      }
      return;
    }
    // Process the incoming data, insert the HTML from data.html into the current element
    this.element.querySelector('#calendulate-streaming-fields').innerHTML = data.html
  }

}
