import {Controller} from "@hotwired/stimulus"
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["dateTimeField", "allDay"];

  connect() {
    this.allDayChanged();
    this.allDayTarget.addEventListener('input', (e) => {
      this.allDayChanged();
    });
  }

  /*Update dateTimeFields input type to datetime-local or date depending on allDay checkbox  */
  allDayChanged() {
    const allDay = this.allDayTarget.checked;
    const dateTimeFields = this.dateTimeFieldTargets;
    dateTimeFields.forEach(dateTimeField => {
      if (allDay) {
        if (dateTimeField.type === 'date') { return; }
        // store the time value (without date) in a data attribute so we can restore it later
        const [date, time] = dateTimeField.value.split('T');
        dateTimeField.value = ''; // clear the value to avoid browser validation errors
        dateTimeField.dataset.time = time;
        dateTimeField.type = 'date';
        dateTimeField.value = date;
      } else {
        // restore the time value
        const time = dateTimeField.value.split('T')[1] || dateTimeField.dataset.time || '00:00';
        const date = dateTimeField.value.split('T')[0];
        dateTimeField.value = ''; // clear the value to avoid browser validation errors
        dateTimeField.type = 'datetime-local';
        dateTimeField.value = `${date}T${time}`;
      }
    });
  }
}
