import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { interval: Number }
  polling = null

  connect() {
  }

  poll() {
    const meta = this.element.querySelector('meta[name="refresh-interval-value"]');
    const newInterval = meta ? parseInt(meta.content, 10) : null;

    if (meta && newInterval !== this.intervalValue) {
      this.intervalValue = newInterval;
    }
    const srcMeta = this.element.querySelector('meta[name="refresh-src"]');
    const newSrc = srcMeta ? srcMeta.content : null;

    if (this.intervalValue > 0) {
      if (newSrc && this.element.src !== newSrc) {
        console.log('refreshing', this.element.src, newSrc);
        this.element.src = newSrc;
      } else if (!newSrc) {
        this.stopPolling()
      }
      else {
        this.element.reload();
      }
    }
  }

  intervalValueChanged() {
    this.restartPolling();
  }

  restartPolling() {
    this.stopPolling(true);
    if (this.intervalValue > 0) {
      this.polling = setInterval(() => { this.poll() }, this.intervalValue);
    }
  }

  stopPolling(restarting = false) {
    if (this.polling) {
      clearInterval(this.polling);
      this.polling = null;
      // if (!restarting) this.dispatch();
    }
  }

  disconnect() {
    this.stopPolling();
  }
}
